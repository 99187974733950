
@media (min-width: 1040px){
  body .image-selector {
    position: relative;
    margin-bottom: 15vh;
    height: 100%;
    display: block;
    .svg-container {
      width: 100% !important;
      height: auto;
      text-align: center;
      position: relative;
      inset: 0 !important;
      top: 0 !important;
    left: 0 !important;
    margin-bottom: 10vh;
    }
    &.mask .svg-container {
      margin-bottom: 5vh;
    }
    &.accueil {
      margin-bottom: 0;
    }
    #svg-compteur-saphir div{
      text-align: center;
    }
    #svg-filter-saphir div{
      text-align: center;
    }
    #svg-geoloc-saphir div{
      text-align: center;
    }
    #svg-support-saphir div{
      text-align: center;
    }
    #svg-earth-onyx{
      text-align: left;
    }
  }

  body .onyx-container .image-selector{
    #svg-export-onyx{
      position: relative;
      .text-filtre{
        text-align: center;
      }
    }
    #svg-filtre-onyx{
      position: relative;
      .text-filtre{
        text-align: center;
      }
    }
    #svg-onyx{
      height: 65vh;
      margin-bottom: 15vh;
    }
    .image-onyx{
      width: 100%;
      position: relative;
      margin-top: 104px;
    }
    #svg-earth-onyx{
      width: 100%!important;
      text-align: center;
      div{
        justify-content: center!important;
      }
      h3{
        text-align: center;
        width: 100%;
      }
    }
  }
    

  body .saphir-container .image-selector .svg-container{
    position: relative;
  }
}

@media (max-width: 1200px) {
  body #root{
    .compatible-container {
      .vitrine{
        justify-content: center;
      }
      .menu-compatible .sections {
        width: auto;
        .section-svg{
          width: 50%;
          min-width: 150px;
        }
      }
    }
    .compatible-container .materiels-container {
      margin-top: 0;
      padding: 0;
      .vitrine {
        flex-wrap: wrap;
        .card{
          min-width: 320px;
        }
      }
    }
    .contact-container {
      .center-container .device-box {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        div:nth-child(1n){
          top: 0!important; 
          left: 0!important;
          flex-direction: column;
          width: fit-content;
          width: moz-fit-content;
          align-items: center;
        }
        .svg-box{
          margin: 0;
          margin-bottom: 10px;
          svg{
            text-align: center;
          }
        }
      }
      .top-container {
        margin: 0;
        .mail-box{
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  body .crystalcloud-container {
    .footer {
      margin-top: 500px;
    }
  }
  .nav-item {
    a {
      font-size: 1rem;
    }
  }

  body .tourmaline-container #svg-filter-tm {
    inset: 10% 5%;
    top: 10%;
    left: 5%;
  }
}
@media (min-width: 1600px) {
  body .crystalcloud-container .documentation-container {
    margin-top: 20vh;
  }
}
@media (max-width: 1800px) {
  body .crystalcloud-container{
    #svg-alert {
      inset: 25% 70%;
      top: 25%;
      left: 70%;
}
  }
  body .saphir-container {
    #svg-filter-saphir {
      inset: 58% 55%;
      top: 58%;
      left: 55%;
      span {
        line-height: 35px;
        font-size: 35px;
      }
      svg {
        height: 5em;
      }
    }
  }
}

@media (max-width: 1600px) {
  body .crystalcloud-container {
    #svg-vm {
      inset: 25% 70%;
      top: 25%;
      left: 70%;
    }

    #svg-alert {
          inset: 25% 70%;
          top: 25%;
          left: 70%;
    }
  }

  body .saphir-container {
    #svg-filter-saphir {
          inset: 60% 55%;
          top: 60%;
          left: 55%;
    margin-top: 0;
    }
    #svg-GSM-saphir {
         inset: 30% 85%;
         top: 30%;
         left: 85%;
    }
  }

  body .onyx-container {
    #svg-filtre-onyx {
      inset: 100px 2%;
      top: 100px;
      left: 2%;
      text-align: left;
    }
  }

  body .crystalcloud-container .svg-container {
    span {
      line-height: 35px;
      font-size: 35px;
    }
    svg {
      height: 5em;
    }
  }

  body .dld-container #svg-accueil-cc .onyx-export {
    span {
      line-height: 35px;
      font-size: 35px;
    }
    svg {
      height: 5em;
    }
  }

  body .mbox-container #svg-accueil-cc .onyx-export {
    span {
      line-height: 35px;
      font-size: 35px;
    }
    svg {
      height: 5em;
    }
  }

  body .saphir-container .svg-container {
    span {
      line-height: 35px;
      font-size: 35px;
    }
    svg {
      height: 5em;
    }
  }

  body .onyx-container .svg-container {
    span {
      line-height: 35px;
      font-size: 35px;
    }
    svg {
      height: 5em;
    }
  }
}

@media (max-width: 1480px) {
  .contact-container.responsiv .center-container .formulaire-container {
    padding-bottom: 70px;
    .svg-send {
      bottom: 5px;
      right: 5px;
      svg {
        height: 60px;
      }
    }
  }
  body .crystalcloud-container .svg-container span {
    line-height: 30px;
    font-size: 30px;
  }

  body .saphir-container .svg-container span {
    line-height: 30px;
    font-size: 30px;
  }

  body .crystalcloud-container {
    #svg-alert {
          inset: 20% 70%;
          top:20%;
          left: 70%;
    }
    #svg-vm {
      inset: 25% 75%;
      top: 25%;
      left: 75%;
    }
  }
}

@media (max-width: 1040px) {
  .responsiv{
    .contact-container {
      .telephone-number{
        left: 0;
        .text-container h3{
          font-size: 22px;
        }
      }
      .center-container{
        margin: 0;
      }
    }
  }
  .menu-header .header{
    position: relative;
    width: 100%;
  }
  .home-low-debit-container{
    .section{
      height: 70vh;
    }
    .home-title h1{
      font-size: 3rem;
    }
  }
  .onyx-container .image-selector{
    margin-top: 10vh;
  }
  .onyx-container .documentation-container{
    margin-top: 10%;
  }
  .center-animation{
    top: 60px!important;
    bottom: initial;
    .text-container h5{
      font-size: 23px;
    }
    .scroll-animation{
      width: 30px;
      height: 50px;
      svg{
        height: 20px;
      }
    }
  }
  .image-seletor{
    height: 70vh;
    img{
      margin-top: 3vh;
    }
  }

  .support-container {
    .section-container {
      justify-content: center;
    }
    .image-container {
      height: 80vh;

      h1 {
        inset: 10% 45%;
        top: 10%;
        left: 45%;
      }
    }
  }
  body .indicatorBar {
    top: 0;
    position: relative;
    padding: 0 5% !important;
  }
  .contact-container {
    .top-container {
      justify-content: center;
      margin-left: 0;
      .mail-box {
        display: none;
      }
    }
    .center-container {
      flex-wrap: wrap;
      margin-left: 0;
      margin-top: 5vh;
      .formulaire-container .section textarea {
        height: 20vh;
      }
      .formulaire-container .triangle-container {
        display: none;
      }
      .image-contact {
        display: none;
      }
      .device-box {
        width: 100%;
        margin: 0;
        padding: 0;
        .svg-box {
          display: none;
        }
        svg {
          display: none;
        }
        .telephone-number {
          margin-top: 0;
          left: 0;

          .triangle-container {
            display: none;
          }
        }
      }
    }
  }
  .compatible-container
    .applications-container
    .section
    .logo-container
    .svg-logo {
    height: 8em;
  }
  .applications-container {
    flex-direction: column;
    .section {
      width: 100%;
      .image-container {
        display: none;
      }
    }
  }

  .comparateur-container {
    .section {
      img {
        width: 100%;
        height: auto;
      }

      .comparateur-btn button {
        width: 100%;
      }
    }
  }
  .contact-container .telephone-number .text-container h3 {
    font-size: 18px;
  }
  .materiels-container {
    padding: 0;
  }
  .mbox-container {
    .footer {
      margin-top: 200px;
      .image-container {
        margin-top: -20%;
      }
      .footer-section .section {
        width: 50%;
      }
    }
    #svg-accueil-cc {
      position: relative;
      inset: 0;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      margin-top: 20px;
      .onyx-export {
        display: flex;
        flex-direction: column;
      }
    }
    .schema-container {
      padding: 0 10px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .how-container {
      flex-direction: column;
      .text-container {
        width: 100%;
      }
      .description {
        width: 100%;
        text-align: center;
      }
    }
    .partenaire-container {
      padding: 10%;
    }
    .explication-container .image-container img {
      width: 100%;
      height: auto;
    }
    .measure-container {
      height: 80vh;
      .measure {
        justify-content: center;
      }
      .container {
        h4 {
          width: 100%;
        }
        .image-container {
          inset: 0;
          top: 0;
          left: 0;
          position: relative;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  body .site-map-container.responsiv {
    width: 100%;
    .maps{
      flex-direction: column;
      padding: 0 5px;
    }
  }
  body .mbox-container.responsiv{
    .compatible-container {
      .menu-compatible .sections{
        width: 100%;
      }
      .logiciels-container{
        display: block;
        .card{
          width: 100%;
          .image-container{
            display: none;
          }
          .button-container {
            width: 100%;
            svg{
              margin-bottom: 20px;
            }
            button svg{
              margin-left: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .footer{
      padding: 40% 10px 10% 10px;
      margin-top: 200px;
      .image-container{
        margin-top: -25%;
      }
      .footer-section .section{
        width: 50%;
      }
    }
    .svg-container {
      svg{
        fill: 3em!important;
      }
      h3{
        font-size: 30px!important;
      }
      span{
        font-size: 20px!important;
        line-height: 25px!important;
      }
    }
    #svg-accueil-cc{
      width: 100%;
      inset: 0;
    }
    .measure-container .image-container{
      width: 100%;
      img{
        margin-top: 0;
      }
      h4{
        font-size: 30px;
        line-height: 35px;
        text-align: center;
      }
      .button-download{
        margin-top: 0;
      }
    }
    .how-container {
      .text-container{
        width: 100%;
        h2{
          font-size: 30px;
        }
        h1{
          font-size: 35px;
        }
      }
      .description{
        width: 100%;
        span{
          font-size: 20px;
        }
      }
    }
  }
  
  body .support-container.responsiv{
    .image-container {
      padding-top: 20px;
      h1{
        position: relative;
        inset: 0;
        font-size: 70px;
      }
    }
  }

  body .fiche-technique-container.responsiv{
    .applications-container .section{
      width: 100%;
      .image-container{
        display: none;
      }
    }
    .calculator-container{
      padding: 5%;
      .header .section .device-card .section-card h2{
        font-size: 20px;
      }
      .tab-container .column .row h5{
        font-size: 20px;
      }
      .tab-container .column .row h4{
        font-size: 20px;
      }
    }
    .top-container .image-container img{
      width: 100%;
      height: auto;
    }
    .mobile-section .section:nth-child(3){
      border: 0;
    }
    .section-container{
      padding: 0 5%;
    }
    .header-fiche{
      height: initial;
    }
    .caracteristique-container{
      height: initial;
    }
  }

  body .container-mat.responsiv{
    .comparateur-container .section{
      width: 100%;
      .comparateur-btn button{
        width: 100%;
      }
    }
    .legend-bar{
      padding: 10px 0;
      position: relative;
      top: 0;
    }
    .materiels-container{
      margin-top: 0;
      .device-section .section{
        width: 100%;
      }
    }
  }

  body .dld-container {
    .measure-container {
      .button-download{
        margin-top: 0;
      }
      .image-container img{
        height: 9em;
      }
    }
    .compatible-container {
      .menu-compatible .sections{
        width: 100%;
      }
      .logiciels-container{
        display: block;
        .card{
          width: 100%;
          .image-container{
            display: none;
          }
          .button-container {
            width: 100%;
            button svg{
              margin-left: 0;
            }
          }
        }
      }
    }
    .how-container {
      display: block;
      .text-container {
        width: 100%;
      }
      .description {
        width: 100%;
        text-align: center;
      }
    }

    .mecanisme-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #svg-accueil-cc {
      position: relative;
      inset: 0;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      margin-top: 20px;
      .onyx-export {
        display: flex;
        flex-direction: column;
      }
    }

    .documentation-container {
      margin-left: 0;
      .download-documentation {
        width: 40%;
      }
    }

    .footer {
      margin-top: 100px;
      padding: 30% 10% 8% 10%;
      &.dld {
        .footer-section .section {
          width: 50%;
        }
        .image-container {
          margin-top: -10%;
        }
      }
    }
  }

  .menu-header .mini-menu .hamburger {
    right: 0px;
  }

  body .onyx-container {
    #svg-earth-tablet {
      position: relative;
      inset: 0;
      top: 0;
      left: 0;
      padding: 15px;
    }

    #svg-filtre-onyx {
      position: relative;
      inset: 0;
      top: 0;
      left: 0;
      width: 100%;
      div {
        text-align: center;
      }
    }

    #svg-export-onyx {
      position: relative;
      inset: 0;
      top: 0;
      left: 0;
      width: 100%;
      div {
        text-align: center;
      }
    }
    .image-onyx {
      position: relative;
      margin-top: 104px;
      top: 0;
      width: 100%;
    }
  }
  body .saphir-container {
    .footer {
      margin-top: 300px;
      padding: 30% 10% 5% 10%;
      .footer-section {
        padding: 0;
      }
    }

    .compatible-container .vitrine .card .button-container button {
      width: 100%;
    }

    #svg-saphir {
      margin-top: 75px;
    }

    .svg-container {
      text-align: center;
    }

    #svg-site-saphir {
      width: 100%;
      inset: 0;
      top: 0;
      left: 0;
    }

    #svg-compteur-saphir {
      width: 100%;
      inset: 0;
      top: 0;
      left: 0;
      div {
        text-align: center;
      }
    }

    #svg-filter-saphir {
      width: 100%;
      inset: 0;
      top: 0;
      left: 0;
      div {
        text-align: center;
      }
    }

    #svg-concat-saphir {
      width: 100%;
      inset: 0;
      top: 0;
      left: 0;
      div {
        text-align: center;
      }
    }

    #svg-geocodage-saphir {
      width: 100%;
      inset: 0;
      top: 0;
      left: 0;
      div {
        text-align: center;
      }
    }

    #svg-GSM-saphir {
      width: 100%;
      inset: 0;
      top: 0;
      left: 0;
      div {
        text-align: center;
      }
    }

    #svg-geoloc-saphir {
      width: 100%;
      inset: 0;
      div {
        text-align: center;
      }
    }

    #svg-support-saphir {
      width: 100%;
      inset: 0;
      top: 0;
      left: 0;
      div {
        text-align: center;
      }
    }

    #svg-securite-saphir {
      width: 100%;
      inset: 0;
      top: 0;
      left: 0;
      div {
        text-align: center;
      }
    }

    .video-container video {
      margin-top: 5vh;
      width: 100%;
    }

    .svg-container {
      position: relative;
      height: fit-content;
    }

    #svg-filter-tm {
      width: 100%;
      text-align: center;
    }
  }

  body .tourmaline-container {
    .documentation-container {
      margin-top: 15%;
      flex-direction: column;
      .catalogue {
        width: 100%;
      }
      .download-documentation {
        width: 100%;
        .button-download {
          justify-content: center;
        }
      }
    }

    .svg-container {
      position: relative;
      height: fit-content;
    }

    #svg-filter-tm {
      width: 100%;
      text-align: center;
      margin-bottom: 5vh;
      inset: 0;
      top: 0;
      left: 0;
    }
  }

  .footer.crystalcloud .footer-section {
    padding: 0;
  }

  .menu-header .logo-header {
    width: fit-content;
  }

  .connexion-component {
    flex-direction: column;
    .image-container {
      width: 100%;
      img {
        width: 80%;
        height: auto;
        margin-left: 10%;
      }
    }
    .text-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      svg {
        width: 15%;
      }
      span {
        margin-left: 5%;
        width: 80%;
      }
    }
  }

  body .onyx-container.responsiv{
    .indicatorBar .padding-bar{
      padding: 0;
    }
    .image-onyx{
      margin-top: 10px;
    }
    .svg-container {
      svg{
        fill: 3em!important;
      }
      h3{
        font-size: 30px!important;
      }
      span{
        font-size: 20px!important;
        line-height: 25px!important;
      }
    }
    .compatible-container {
      .menu-compatible .sections{
        width: 100%;
      }
      .logiciels-container{
        display: block;
        .card{
          width: 100%;
          .image-container{
            display: none;
          }
          .button-container {
            width: 100%;
            button svg{
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  body .saphir-container.responsiv{
    .svg-container{
      position: relative!important;
      height: fit-content!important;
      width: 100%!important;
      inset: 0!important;
      div{
        text-align: center!important;
      }
    }
    .indicatorBar .padding-bar{
      padding: 0;
    }
    #svg-saphir{
      margin-top: 20px;
    }
    .image-selector{
      height: fit-content;
      margin-bottom: 20px;
    }
    .svg-container {
      svg{
        fill: 3em!important;
      }
      h3{
        font-size: 30px!important;
      }
      span{
        font-size: 20px!important;
        line-height: 25px!important;
      }
    }
    .compatible-container {
      .menu-compatible .sections{
        width: 100%;
      }
      .logiciels-container{
        display: block;
        .card{
          width: 100%;
          .image-container{
            display: none;
          }
          .button-container button svg{
            margin-left: 0;
          }
        }
      }
    }
  }

  body .crystalcloud-container {
    &.responsiv{
      .cc-alertes .svg-box{
        margin: 20px 0 0 0;
      }
      .image-selector{
        height: fit-content;
        margin-bottom: 20px;
      }
      .svg-container {
        svg{
          fill: 3em;
        }
        h3{
          font-size: 30px;
        }
        span{
          font-size: 20px;
          line-height: 25px;
        }
      }
      .footer{
        margin-top: 250px;
        .footer-section{
          padding: 0;
          .section{
            width: 50%;
          }
        }
      }
      .compatible-container .applications-container{
        display: block;
        .section{
          width: 100%;
          .image-container{
            display: none;
          }
        }
      }
    }
    &.home{
      .image-selector{
        height: 100%;
        display: block;
        margin-bottom: 0;
      }

      .timeline-wrapper{
        z-index:0!important;
        width: auto!important;
        position: relative!important;
        top: 0!important;
        right: 0!important;
        left: 0;
        margin-top: 0;
        .bar .in-progress{
          height: 50px;
          width: 15px;
        }
        .title{
          position: absolute;
          top: 60px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          left: 0;
          &.opt2{
            top: 180px;
            left: 50%;
          }
          &.opt3{
            top: 300px;
          }
          &.opt4{
            top: 420px;
            left: 50%;
          }
          &.opt5{
            top: 540px;
          }
        }
      }
    }
    .compatible-container
      .applications-container
      .section
      .image-container
      img {
      width: 100%;
      height: auto;
    }
    .download-documentation .button-download {
      justify-content: center;
    }
    .cc-alertes .svg-box svg {
      height: 4em;
    }
    .image-selector {
      position: relative;
      margin-bottom: 15vh;
      .svg-container {
        width: 100% !important;
        height: auto;
        text-align: center;
        position: relative;
        inset: 0 !important;
        top: 0 !important;
      left: 0 !important;
      }
      &.mask .svg-container {
        margin-bottom: 5vh;
      }
    }

    .footer {
      margin-top: 400px;
      padding: 35% 0% 5% 0%;
      .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -30%;
        margin-left: 0;
        width: 100%;
        h2 {
          font-size: 3rem;
        }

        img {
          width: 60%;
        }
      }
    }

    .documentation-container {
      margin-top: 200px;
    }
  }

  .calculator-container {
    padding: 5% 10px;
  }
  .fiche-technique-container {
    .top-container {
      padding: 0 10px;
    }
  }
  .menu-header {
    svg {
      height: 1.4em !important;
    }

    .nav-item {
      a {
        font-size: 1rem;
      }
    }
  }

  .contact-container .center-container .formulaire-container {
    padding-bottom: 60px;
    .svg-send {
      bottom: 5px;
      right: 5px;
      svg {
        height: 50px;
      }
    }
  }
  .logiciels-container.produits.responsiv {
    margin-top: 0;
      height: unset;
      justify-content: normal;
      .center-container {
        width: 100%;
      }

      .section-container {
        width: 100%;
      }
      .video-container {
        width: 100%;
        height: auto;
        position: unset;
        margin-top: 5%;
      }

      .section-container img {
        width: 50%;
        height: auto;
        margin-left: 25%;
      }

      .section-container .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .button-download {
          margin-left: 0;
        }
      }

      .video-container {
        margin-top: 0;
      }
    }

  body .crystalcloud-container .cc-alertes .svg-box {
    position: relative;
    margin-top: 74px;
    margin-bottom: 20px;
    top: 0;
  }
}

@media (max-width: 800px) {
  .fiche-technique-container .caracteristique-container .section-container {
    padding: 0;
  }
  .compatible-container .menu-compatible .sections .section-svg{
    border: none !important;
  }
}

@media (max-width: 1000px) {
  .container-mat .legend-bar {
    padding: 10px;
  }
}

@media (max-width: 600px) {

  .error-container .wrapper{
    width: 100%;
    padding: 20px;
  }
  .support-container.responsiv {
    .image-container {
      h1 {
        inset: 5% 0;
        top: 5%;
        left: 0;
        width: 100%;
        text-align: center;
      }
      .hotline {
        inset: 0;
        top: 0;
      left: 0;
        width: 100%;
        text-align: center;
        top: 30%;
      }

      .remote-maintenance {
        inset: 0;
        top: 0;
      left: 0;
        width: 100%;
        text-align: center;
        top: 70%;
      }

      .test {
        width: clamp(200px, 50%, 600px);
      }
    }
  }

  .fiche-technique-container .header-fiche {
    height: fit-content;
    margin: 5vh 0;
  }
  body .dld-container {
    .footer.dld {
      padding: 50% 10% 8% 10%;
      .image-container {
        margin-top: -10%;
        img {
          width: 100%;
        }
        h2 {
          font-size: 3rem;
        }
      }
    }
    .documentation-container {
      padding: 0;
      margin-left: 0;
      flex-direction: column-reverse;
      .download-documentation {
        width: 100%;
        .button-download {
          justify-content: center;
        }
      }
      .catalogue {
        width: 100%;
      }
    }

    #svg-accueil-cc {
      height: fit-content;
      .onyx-export span {
        line-height: 25px;
        font-size: 20px;
      }
    }
    .explication-container {
      margin-top: 10%;
    }

    .measure-container {
      margin-top: 0;
      h4 {
        font-size: 30px;
        line-height: 40px;
        width: 100%;
      }
    }
    .mecanisme-container .container {
      height: 25vh;
    }

    .how-container {
      .description span {
        line-height: 25px;
        font-size: 20px;
      }
      .text-container {
        h2 {
          font-size: 30px;
        }
        h1 {
          font-size: 50px;
        }
      }
    }
  }

  body .mbox-container {
    .image-selector {
      height: fit-content;
      margin-bottom: 5vh;
    }
    .documentation-container {
      padding: 0;
      margin-left: 0;
      flex-direction: column;
      .download-documentation {
        width: 100%;
        .button-download {
          justify-content: center;
        }
      }
      .catalogue {
        width: 100%;
      }
    }
    #svg-accueil-cc {
      height: fit-content;
      .onyx-export span {
        line-height: 30px;
        font-size: 25px;
      }
    }
    .how-container {
      .description span {
        line-height: 30px;
        font-size: 25px;
      }
      .text-container {
        h2 {
          font-size: 40px;
        }
        h1 {
          font-size: 60px;
        }
      }
    }
    .partenaire-container {
      margin-bottom: 5vh;
      h2 {
        font-size: 35px;
      }
      h1 {
        font-size: 40px;
      }
    }
    .measure-container {
      height: fit-content;
      margin-top: 0;
      margin-bottom: 5vh;
      .container h4 {
        font-size: 30px;
        line-height: 40px;
        width: 100%;
      }
    }
    .footer {
      padding: 15% 10px 10% 10px;
      .image-container {
        margin-top: -40%;
        h2 {
          font-size: 3rem;
        }
        img {
          width: 80%;
        }
      }
    }
  }

  .modal .modal-content {
    width: calc(100vw - 10px);

    .selector-container {
      max-width: 100%;
      overflow: scroll;
    }

    .item-carousel {
      min-width: 90%;
      margin-right: 20px;

      .box {
        h5 {
          text-align: center;
          font-family: CandleLight;
          margin-bottom: 0;
          color: black;
          font-size: 40px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .menu-header .mini-menu .hamburger {
    right: 0;
  }

  .image-selector {
    height: 60vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h3 {
      margin-bottom: 10%;
    }
    img {
      margin-top: 10%;
    }
  }

  body .onyx-container {
    .documentation-container {
      flex-direction: column;
      .catalogue {
        width: 100%;
      }
      .download-documentation {
        width: 100%;
      }
    }
    .footer {
      margin-top: 250px;
      padding: 60% 0% 5% 0%;
      .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -60%;
        margin-left: 0;
        width: 100%;
        h2 {
          font-size: 3rem;
        }

        img {
          width: 50%;
        }
      }
      .footer-section {
        padding: 0;
        .section {
          width: 50%;
        }
      }
    }
  }

  body .saphir-container.responsiv {
    .documentation-container {
      flex-direction: column;
      .catalogue {
        width: 100%;
      }
      .download-documentation {
        width: 100%;
      }
    }
    .indicatorBar .padding-bar {
      padding: 0;
    }
    .footer {
      margin-top: 250px;
      padding: 85% 0% 5% 0%;
      .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -50%;
        margin-left: 0;
        width: 100%;
        h2 {
          font-size: 3rem;
        }

        img {
          width: 50%;
        }
      }
      .footer-section {
        padding: 0;
        .section {
          width: 50%;
        }
      }
    }
  }
  body .maconso-container {
    .indicatorBar {
      padding: 0;
    }
  }
  body .intervention-container {
    .indicatorBar {
      padding: 0;
    }
  }
  body .tourmaline-container {
    .indicatorBar {
      padding: 0;
    }
    .svg-box {
      padding: 0 20px;
    }
    .documentation-container {
      margin-top: 75px;
      padding: 0;
      .catalogue {
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
      }
      .download-documentation .button-download {
        justify-content: center;
      }
    }

    .footer {
      margin-top: 250px;
      padding: 22% 0% 5% 0%;
      .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -40%;
        margin-left: 0;
        width: 100%;
        h2 {
          font-size: 3rem;
        }

        img {
          width: 60%;
        }
      }
    }

    .connexion-container {
      .top {
        flex-direction: column;
        .image-container {
          width: 100%;
        }
        .description {
          width: 100%;
          text-align: center;
        }
      }
      .bottom {
        width: 100%;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .section {
          &:nth-child(2) {
            border-top: 1px solid grey;
            border-bottom: 1px solid grey;
            border-left: none;
            border-right: none;
          }
        }
      }
    }

    .documentation-container {
      width: 90%;
      margin-left: 5%;
    }
  }

  body .crystalcloud-container {
    .documentation-container {
      margin-top: 0;
      flex-direction: column;
      .catalogue {
        width: 100%;
      }
      .download-documentation {
        width: 100%;
      }
    }
    .footer {
      margin-top: 250px;
    }
    .indicatorBar {
      padding: 0;
    }
    .connexion-component .text-container {
      svg {
        display: none;
      }

      span {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  body .crystalcloud-container .footer .image-container {
    margin-top: -40%;
  }
  .logiciels-container {
    margin-top: 30px;
    .center-container .text-container h5 {
      text-align: center;
    }
    &.applications {
      height: unset;
      justify-content: normal;
      margin-top: 0;
      .section-container {
        width: 100%;
      }
      .video-container {
        width: 100%;
        height: auto;
        position: unset;
        margin-top: 5%;
      }

      .section-container img {
        width: 100%;
        height: auto;
      }
    }
  }
  .comparateur-component-container .top-container {
    margin-top: 0;
    .title img {
      width: 100%;
      height: auto;
    }
    .image-container img {
      width: 100%;
      height: auto;
    }
  }

  .contact-container {
    .top-container {
      margin-left: 0;
      justify-content: center;
      align-items: center;

      .image-container {
        margin-left: 0;
        width: 75% !important;
      }
    }
    .image-contact {
      display: none;
    }

    .telephone-number {
      margin-top: 20%;
      width: 100%;
      left: 0;
      .text-container h3 {
        font-size: 20px;
        text-align: center;
      }
    }

    .triangle-container {
      display: none;
    }

    .center-container {
      margin: 0;
      .device-box {
        display: none;
      }
    }

    .center-container .formulaire-container {
      padding-bottom: 60px;
      .svg-send {
        bottom: 5px;
        right: 5px;
        svg {
          height: 50px;
        }
      }
    }
  }

  .poid-container {
    padding: 0;
  }

  .calculator-container .header .section .device-card .section-card {
    img {
      display: none;
    }
    h2 {
      font-size: 2em;
    }
  }

  .materiels-container .device-section {
    .section {
      width: 100%;
    }
  }

  .footer-logiciels .container.right {
    display: none;
  }

  .comparateur-container .section {
    width: 100%;
    .comparateur-btn {
      width: 100%;
      svg {
        margin-bottom: 25px;
      }
      button {
        width: 100%;
      }
    }
  }

  .fiche-technique-container .header-fiche {
    .title h1 {
      text-align: center;
      font-size: 60px;
    }
  }

  .fiche-technique-container {
    .top-container {
      .title {
        width: 50%;
        img {
          width: 100%;
          height: auto;
        }
      }
      .image-container {
        width: 50%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .caracteristique-container {
      height: fit-content;
      margin: 5vh 0;
      .title {
        margin-bottom: 5vh;
      }
      .section-container {
        .mobile-section-container {
          .mobile-section {
            &:nth-child(1) {
              margin-bottom: 2.5vh;
            }
            display: flex;
            .section {
              height: 40%;
            }
          }
        }
      }
    }
  }

  .compatible-container {
    .logiciels-container {
      padding: 0;
      .vitrine .card {
        flex-direction: column;
        padding: 0;
        .image-container {
          width: 100%;
        }

        .button-container {
          width: 100%;
        }
      }
    }
    .materiels-container {
      margin-top: 60px;
      padding: 0;
      .vitrine {
        flex-direction: column;
      }
    }
    .menu-compatible {
      flex-direction: column;
      .text-container {
        width: 100%;
      }
      .sections {
        width: 100%;
      }
    }
  }
}
