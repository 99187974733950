.admin-login-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    .wrapper {
        width: 500px;
        border: 1px solid #cf7830;
        position: relative;
        padding: 20px;

        .title {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-50%);

            h2 {
                width: fit-content;
                background-color: white;
                margin: 0 auto;
                padding: 0 20px;
            }
        }

        .login {
            .input-box {
                margin-top: 20px;

                .MuiFormControl-root {
                    .Mui-focused {
                        color: #cf7830;

                        &::after {
                            border-bottom: 2px solid #cf7830;
                        }
                    }

                    input {
                        color: black;
                    }
                }
            }

            .login-button {
                display: flex;
                justify-content: center;
                margin-top: 20px;

                button {
                    color: #cf7830;
                    border-color: #cf7830;

                    &:hover {
                        background-color: #cf783033;
                    }
                }
            }
        }
    }
}

.admin-dashboard-container {
    .onglet {
        width: 100%;
        height: 52px;
        display: flex;

        .onglet-box {
            width: 50%;
            background-color: #333;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;

            &.selected {
                background-color: #cf7830;
            }
        }
    }

    .stuff-container {
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .stuff-box {
            min-width: 400px;
            width: 30%;

            .box-i {
                display: flex;
                padding: 5px;

                .box-dim {
                    display: flex;
                    flex-direction: column;
                    .dimension-box{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .MuiFormControl-root{
                            width: 45%;
                        }
                    }
                    .label-dim{
                        color: rgba(0, 0, 0, 0.6);
                        font-family: "Roboto","Helvetica","Arial",sans-serif;
                        font-weight: 400;
                        font-size: 0.8rem;
                        line-height: 1.4375em;
                        letter-spacing: 0.00938em;
                        padding: 0;
                        position: relative;
                        display: block;
                        transform-origin: top left;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .save {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }
    }
    .job-container{
        position: relative;
        display: flex;
        padding: 20px;

        .save{
            margin: 0 auto;
            width: fit-content;
        }
        .left {
            width: calc(100vw - 800px);
            padding-right: 50px;
            span {
                display: block;
            }

            .svg-box{
                width: fit-content;
            }


            .title-input input{
                    font-size: 2rem;
            }

            .box{
                display: flex;
                align-items: center;
                .c100{
                    width: 100px;
                    margin: 0 20px;
                }
            }
    
            .flex-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
    
                .share-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1.5px solid black;
                    color: black;
                    border-radius: 20px;
                    padding: 5px 20px;
                    width: fit-content;
                }
            }
    
            .text-box {
                margin-bottom: 20px;
                textarea{
                    border: none;
                }
                h4 {
                    color: #cf7830;
                    margin-bottom: 20px;
                }
                span{
                    display: flex;
                    align-items: center;
                }
            }
        }
        .jobs-wrapper {
            width: 800px;
            min-height: 300px;
            margin: 0 auto;
            max-height: 800px;
            overflow-y: scroll;

            .add-job{
                border: 1px solid #cf7830;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                cursor: pointer;
                transition: 0.3s;
                margin: 0 auto;
                &:hover{
                    background-color: #cf7830;
                }
            }
    
            .card-j {
                background-color: white;
                padding: 15px;
                margin-bottom: 20px;
                border: 1px solid #cf7830;
                transition: 0.3s;
                cursor: pointer;
    
                &:hover {
                    box-shadow: 5px 5px 5px #cf7830;
                }
    
                .top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
    
                    h3 {
                        margin: 0;
                        width: 75%;
                    }
    
                    .rect {
                        border: 1px solid #cf7830;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 50px;
                        height: 25px;
    
                        h5 {
                            margin: 0;
                            color: #cf7830;
                        }
                    }

                    .card-options{
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        .delete-job{
                            margin-left: 15px;
                            height: 30px;
                            width: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            border: 1px solid #cf7830;
                            cursor: pointer;
                            &:hover{
                                background-color: #cf7830;
                            }
                        }
                    }
                }
            }
        }
    }
}