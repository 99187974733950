.maconso-container{

    @keyframes moveFromLeft {
        from {
          transform: translateX(-70%);
        }
      
        to {
            transform: translateX(-50%);
        }
      }

      @keyframes moveFromRight {
        from {
          transform: translateX(70%);
        }
      
        to {
            transform: translateX(50%);
        }
      }

      @keyframes moveFromBottom {
        from {
          transform: translateY(50%);
          opacity: 0;
        }
      
        to {
            transform: translateY(5%);
            opacity: 1;
        }
      }

    .box {
        &.first-anim{
            .b35{
                &.left{
                    animation: moveFromLeft 1.5s linear;
                }
                &.right{
                    animation: moveFromRight 2.5s linear;
                }
            }
            .b30{
                animation: moveFromBottom 1s ease;
            }
        }
        &.second{
            margin-top: 200px;
            .b60{
                transform: translateX(-50%) translateY(25%);
            }
            .b40{
                transform: translateX(50%);
            }
        }
        &.third {
            .b40{
                transform: translateX(-50%);
            }
            .b60{
                transform: translateX(50%);
            }
        }
        &.fourth{
            margin-top: 300px;
            .b60{
                transform: translateX(-50%) rotate(-15deg);
            }
            .b40{
                transform: translateX(50%);
            }
        } 
        &.fith{
            margin-top: 200px;
            height: 600px;
            .b60{
                transform: translateX(-50%);
            }
            .b40{
                transform: translateX(50%);
            }
        } 
        &.sixth{
            .b45{
                opacity: 0;
            }
        } 
        
    }
}