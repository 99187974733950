@font-face {
  font-family: "CandleLight";
  src: local("CandleLight"), url(./fonts/ttf/CandleLight.ttf) format("truetype");
}

@font-face {
  font-family: "Amatic-Bold";
  src: local("Amatic-Bold"), url(./fonts/ttf/Amatic-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "TrashHand";
  src: local("TrashHand"), url(./fonts/ttf/TrashHand.ttf) format("truetype");
}

@font-face {
  font-family: "SilentLandfield";
  src: local("SilentLandfield"),
    url(./fonts/ttf/SilentLandfield.ttf) format("truetype");
}

.white-text{
  color: white;
}

html {
  width: 100vw;
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;

  body {
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  div {
    width: 100%;
  }

  nav {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }

  p {
    font-size: 20px;
  }
}

#progressbar {
  position: fixed;
  top: 52px;
  right: 0;
  width: 15px;
  height: 0%;
  border-radius: 0 0 10px 10px;
  background: linear-gradient(to top, #31c6b3, #f2ae2e);
  z-index: 1111111;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a:link {
  text-decoration: none;
}

::-webkit-input-placeholder {
  font-style: italic;
  font-size: 13px;
}
:-moz-placeholder {
  font-style: italic;
  font-size: 13px;
}
::-moz-placeholder {
  font-style: italic;
  font-size: 13px;
}
:-ms-input-placeholder {
  font-style: italic;
  font-size: 13px;
}

html,
body {
  position: relative;
  height: 100%;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

button {
  outline: none !important;
}

.svg-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-container {
  height: fit-content;
  height: moz-fit-content;
}
/*
.tooltip {
  position: fixed !important;
  top: 90px !important;
  transform: translateY(0) !important;
}
*/
//Header
.menu-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  transition: 1s;
  height: 52px;

  .logo-header {
    width: 30%;
    margin-left: 1rem;
    svg {
      height: 40px;
    }
  }

  .header {
    z-index: 10000;
    width: 100vw;
    height: 52px;
    background-color: #333;
    font-size: 20px;
    position: fixed;
    top: 0;

    .navbar {
      padding: 0;
    }

    .navbar-nav {
      height: 100%;
      flex-direction: row;
      a {
        display: flex;
        align-items: center;
        padding: 0;
      }
    }

    div {
      height: 100%;
    }

    .menu-header {
      z-index: 10000;
      text-align: center;

      .menu-item {
        position: relative;
        .count-job{
          position: relative;
          width: fit-content;
          .round{
            position: absolute;
            font-size: 12px;
            top: 3px;
            right: -15px;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            background-color: #b7803c;
            text-align: center;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &:hover {
          .submenu {
            display: block;
          }
        }

        a {
          height: 100%;
        }

        .submenu {
          display: none;
          position: absolute;
          background-color: #333;
          width: max-content;
          min-width: 100%;
          list-style: none;
          padding-left: 0;

          &:hover {
            display: block;
          }

          .dropdown_item-0 {
            animation: translateX 200ms ease-in-out forwards;
            transform-origin: top center;
          }

          .dropdown_item-1 {
            animation: translateX 400ms ease-in-out forwards;
            transform-origin: top center;
          }

          .dropdown_item-2 {
            animation: translateX 600ms ease-in-out forwards;
            transform-origin: top center;
          }

          @keyframes translateX {
            0% {
              opacity: 0;
              transform: translateX(-60px);
            }

            80% {
              transform: translateX(5px);
            }

            100% {
              opacity: 1;
              transform: translateX(0px);
            }
          }

          .submenu-item {
            text-align: left;
            cursor: pointer;
            background-color: #cf7830;
            &.maconso{
              background-color: #D1D246;
            }

            &.crystalcloud {
              background-color: #31c6b3;
            }

            &.intervention{
              background-color: #E96F90;
            }

            &.tourmaline {
              background-color: #3e9f92;
            }

            &.saphir {
              background-color: #cf7830;
            }

            &.onyx {
              background-color: #06d0e7;
            }

            &.dld {
              background-color: #d8a202;
            }

            &.mbox {
              background-color: #208491;
            }

            &:hover {
              background-color: #333;
            }

            a {
              display: block;
              padding: 0.5rem 1rem;
            }

            a:link {
              text-decoration: none;
            }
          }
        }
      }

      .navbar-nav {
        width: 100%;

        .logo-header {
          width: 30%;
          text-align: left;
          padding: 0;
          margin: 0;

          svg {
            height: 2em;
            margin-left: 10%;
          }
        }

        .nav-item {
          width: 100%;
          a {
            color: white;
            margin: 0 0.5rem;
            text-align: left;
          }
        }
      }
    }
  }
}

//body
body {
  .svg-container {
    position: absolute;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    svg {
      height: 7em;
    }
  }

  .tourmaline-container {
    .indicatorBar {
      padding: 0 30%;
    }
    .documentation-container {
      padding: 0 10% 0 0;
      margin-left: 5%;
      .catalogue {
        width: 60%;
      }
      .download-documentation .button-download {
        justify-content: center;
      }
    }

    .tourmaline-accueil {
      z-index: -1;
    }

    #svg-filter-tm {
      inset: 20% 0%;
      top: 20%;
      left: 0;
      width: 30%;
    }

    .footer {
      margin-top: 600px;
    }

    .connexion-container {
      margin: 5% 0;
      padding: 0 10%;
      .top {
        display: flex;
        .image-container {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .description {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .bottom {
        display: flex;
        width: 50%;
        margin-left: 25%;
        margin-top: 5vh;
        .section {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px;
          &:nth-child(2) {
            border-right: 1px solid lightgrey;
            border-left: 1px solid lightgrey;
          }
          h5 {
            margin-bottom: 0;
          }
          svg {
            margin-right: 25px;
          }
        }
      }
    }
  }

  .crystalcloud-container {
    animation: startOpacity 0.5s ease-in-out forwards;

    @keyframes startOpacity {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .footer {
      margin-top: 800px;
    }

    .svg-container {
      height: fit-content;
      h3 {
        font-weight: lighter;
        margin: 1vh 0;
      }
      svg {
        height: 5em;
      }
      span {
        clip-path: unset !important;
        font-weight: bold;
        line-height: 50px;
        font-size: 50px;
      }
    }

    .documentation-container {
      margin-left: 0;
    }

    .download-documentation .button-download {
      justify-content: center;
    }

    .cc-alertes .svg-box {
      position: absolute;
      top: 120px;
      z-index: 10;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    #svg-synchronisation {
      inset: 25% 5%;
      top: 25%;
      left: 5%;
      width: 30%;
    }

    #svg-vm {
      inset: 20% 75%;
      top: 20%;
      left: 75%;
      width: 35%;
    }

    #svg-alert {
      inset: 25% 80%;
      top: 25%;
      left: 80%;
      width: 30%;
    }

    #svg-mask {
      inset: 27% 11%;
      top: 27%;
      left: 11%;
      width: 20%;
    }

    #svg-filtre {
      inset: 30% 5%;
      top: 30%;
      left: 5%;
      width: 30%;
    }

    .indicatorBar {
      padding: 0 35%;
    }
  }

  .maconso-container {
    .indicatorBar {
      padding: 0 35%;
    }
  }
  .intervention-container {
    .indicatorBar {
      padding: 0 35%;
    }
  }

  .saphir-container {
    animation: startOpacity 3s ease-in-out forwards;

    @keyframes startOpacity {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .compatible-container {
      margin-top: 5%;
    }
    .footer {
      .image-container {
        margin-top: -25%;
        h2 {
          margin-top: 35px;
        }
      }
    }

    .video-container {
      display: flex;
      justify-content: center;
    }

    .svg-container h3 {
      margin: 1vh 0;
    }

    #svg-saphir {
      position: relative;
      height: fit-content;
      flex-direction: column;
      .download-documentation .button-download{
            margin: 0;
            justify-content: center;
          }
    }

    .documentation-container {
      margin-left: 0;

      .download-documentation .button-download {
        justify-content: center;
        button {
          border-color: #cf7830;
        }
      }
    }

    .indicatorBar .padding-bar {
      padding: 0 30%;
    }

    .svg-container {
      position: absolute;
      margin-top: 52px;
      height: 25vh;
      h3 {
        font-weight: lighter;
      }
      span {
        clip-path: unset !important;
        font-weight: bold;
        line-height: 50px;
        font-size: 50px;
      }
    }

    .canvas-container {
      width: fit-content;
      height: fit-content;
    }

    .saphir-demo {
      justify-content: center;
      display: flex;
      .canvas-container {
        position: fixed;
        bottom: 0;
      }
    }

    #svg-site-saphir {
      inset: 30% 25%;
      top: 30%;
      left: 25%;
      width: 25%;
    }

    #svg-compteur-saphir {
      inset: 40% 55%;
      top: 40%;
      left: 55%;
      width: 40%;
      div {
        text-align: left;
      }
    }

    #svg-filter-saphir {
      inset: 50% 58%;
      top: 50%;
      left: 58%;
      width: 40%;
      svg {
        height: 6em;
      }
      div {
        text-align: right;
      }
    }

    #svg-concat-saphir {
      inset: 30% 15%;
      left: 15%;
      top: 30%;
      width: 30%;
      svg {
        height: 4em;
      }
    }

    #svg-geocodage-saphir {
      inset: 25% 55%;
      top: 25%;
      left: 55%;
      width: 30%;
    }

    #svg-GSM-saphir {
      inset: 20% 80%;
      top: 20%;
      left: 80%;
      width: 30%;
    }

    #svg-geoloc-saphir {
      inset: 15% 60%;
      top: 15%;
      left: 60%;
      width: 35%;
      div {
        text-align: left;
      }
    }

    #svg-support-saphir {
      inset: 30% 52%;
      top: 30%;
      left: 52%;
      width: 35%;
      div {
        text-align: left;
      }
    }

    #svg-securite-saphir {
      inset: 30% 55%;
      top: 30%;
      left: 55%;
      width: 30%;
    }
  }
}

@keyframes floatingCanvasAlert {
  0% {
    transform: translatey(-40%);
  }
  50% {
    transform: translatey(-50%);
  }
  100% {
    transform: translatey(-40%);
  }
}
@keyframes floatingCanvas4 {
  0% {
    transform: translatey(-30%);
  }
  50% {
    transform: translatey(-40%);
  }
  100% {
    transform: translatey(-30%);
  }
}
@keyframes floatingCanvas5 {
  0% {
    transform: translatey(-35%);
  }
  50% {
    transform: translatey(-45%);
  }
  100% {
    transform: translatey(-35%);
  }
}

@keyframes float {
  0% {
    transform: translatey(35px);
  }
  50% {
    transform: translatey(-35px);
  }
  100% {
    transform: translatey(35px);
  }
}

.connexion-component {
  display: flex;
  padding: 15%;
  align-items: center;
  clip-path: polygon(50% 5%, 100% 0, 100% 100%, 50% 95%, 0 100%, 0 0);
  background-color: white;
  .image-container {
    transform: translatey(35px);
    animation: float 6s ease-in-out infinite;
    width: 49%;
    margin-right: 2%;
    img {
      width: 100%;
    }
  }
  .text-container {
    display: grid;
    width: 49%;
  }
}

.tablette-component {
  display: flex;
  position: fixed;
  top: 120%;
  padding: 0 10%;

  .image-container {
    align-self: center;
    text-align: center;
  }
}

.interface-component {
  display: flex;
  position: fixed;
  top: 120%;
  padding: 0 10%;

  .image-container {
    align-self: center;
    justify-content: center;
    display: flex;
    img {
      align-self: center;
    }
  }
}
.logiciels-container {
  a:link {
    text-decoration: none;
  }
  &.produits {
    .center-container {
      width: 50%;
    }

    .section-container {
      width: 100%;
      svg {
        align-self: baseline;
      }

      img {
        align-self: baseline;
      }

      .button-download {
        width: fit-content;
        margin-left: 20%;
      }

      button {
        background-color: white;
      }

      .text-container {
        margin-left: 15px;

        h5 {
          max-width: fit-content;
          width: 100%;
        }
      }
    }

    .video-container {
      margin-top: 0;
      img {
        margin-top: 10%;
        height: 100%;
        width: auto;
      }
    }
  }
}

.documentation-container {
  display: flex;
  padding: 0 10%;
  margin-left: 10%;

  .catalogue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.download-documentation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
    img {
      width: 100%;
    }
  }

  .button-download {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    button {
      padding: 5px 30px;
      border-radius: 25px;
      border: 1px solid #34827a;
      background-color: white;

      &:hover {
        background-color: lightgrey;
      }
    }
  }
}

.footer {
  background-color: #f2f2f2;
  position: relative;
  padding: 22% 10% 5% 10%;
  margin-top: 500px;

  &.crystalcloud {
    .footer-section {
      padding: 0 15%;
    }

    .last-section {
      padding: 0 15%;
    }

    .image-container h2 {
      color: #31c6b3;
    }
  }

  &.saphir {
    .image-container {
      h2 {
        color: #b77830;
      }
      img {
        height: auto;
        width: 30%;
      }
    }

    .footer-section {
      padding: 0 18%;
    }

    .last-section {
      padding: 0 18%;
    }
  }

  &.onyx {
    padding: 25% 10% 10% 10%;
    .image-container {
      h2 {
        color: #1f8491;
      }
      img {
        height: auto;
        width: 30%;
      }
    }

    .footer-section {
      justify-content: center;
      .section {
        width: 15%;
      }
    }
  }

  &.dld {
    padding: 18% 10% 8% 10%;
    .compatible-container {
      margin-top: 5%;
    }

    .image-container {
      margin-top: -10%;

      h2 {
        margin-bottom: 10%;
        margin-top: 35px;
        color: #d8a202;
      }
    }
    .footer-section {
      justify-content: center;
      .section {
        width: 15%;
      }
    }
  }

  &.mbox {
    padding: 25% 10% 8% 10%;
    margin-top: 300px;
    .image-container {
      margin-top: -15%;

      img {
        margin-top: -15%;
        width: 60%;
      }

      h2 {
        color: #1f8491;
      }
    }
    .footer-section {
      justify-content: center;
      .section {
        width: 15%;
      }
    }
  }

  .image-container {
    position: absolute;
    top: 0;
    margin-top: -30%;
    width: 70%;
    margin-left: 5%;
    text-align: center;

    h2 {
      color: #34827a;
      font-size: 4rem;
      font-weight: bold;
    }

    img {
      height: auto;
      width: 75%;
    }
  }

  .footer-section {
    display: flex;
    justify-content: space-between;
  }

  .last-section {
    display: flex;
    justify-content: center;

    .section {
      width: 33.33%;
    }
  }

  .section {
    margin: 20px;

    h5 {
      margin-bottom: 0;
      margin-top: 0.5rem;
    }
  }
}

.logiciels-container {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 0 10%;
  .section-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    z-index: 2;

    img {
      align-self: center;
    }

    .text-container {
      h5 {
        margin: 25px 0;
        width: 37%;
      }
    }

    .button-download {
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        padding: 5px 30px;
        border-radius: 25px;
        border: 1px solid #cf7830;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 15px;
          transform: rotate(15deg);
        }

        span {
          width: 95%;
        }

        &:hover {
          background-color: lightgrey;
        }
      }
    }
  }
  .video-container {
    width: 70%;
    height: 150%;
    display: flex;
    margin-top: 25%;
    left: 30%;
    position: absolute;
    z-index: 1;

    .img-mbox {
      margin-top: 10%;
    }

    img {
      width: 80%;
    }
  }

  &.produits .video-container {
    width: 50%;
    z-index: -1;
  }
}

.footer-logiciels {
  padding: 3% 20% 0 20%;
  background-color: #333;

  .adresse {
    width: fit-content;
    width: -moz-fit-content;
  }

  a {
    color: white;
    &:link {
      text-decoration: none;
    }
    &:hover {
      color: #cf7830;
    }
  }

  svg {
    fill: white;
  }

  span {
    color: white;
  }

  ul {
    margin-bottom: 0;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    &.left {
      flex-direction: column;
      align-items: center;
    }

    &.right {
      justify-content: center;

      li {
        display: flex;
        color: white;
        align-items: center;
        svg {
          width: 3rem;
          fill: #b77830;
        }
      }
    }
  }
}

.copyright {
  text-align: center;
  background-color: #b77830;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 0;
  span {
    color: white;
    font-size: 11px;
  }
  .separator{
    border-left: 1px solid white;
    margin: 0 10px;
    height: 10px;
    width: 0;
  }
  a{
    display: flex;
  }
}

.indicatorBar {
  height: 52px;
  position: fixed;
  top: 52px;
  background-color: lightgrey;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;

  .svg-indicator {
    display: flex;
    justify-content: center;
  }
}

.measure-container {
  margin-top: 52px;
  .image-container {
    width: 100%;
    justify-content: center;

    img {
      height: 9em;
      width: auto;
    }
  }

  .container {
    display: flex;
    align-items: center;
  }

  h4 {
    font-family: CandleLight;
    margin-bottom: 0;
    color: black;
    font-size: 60px;
    line-height: 75px;
    width: 55%;
    margin-top: 3%;
    text-align: center;
  }
}

.dld-container {
  height: 100%;

  .filter {
    margin-top: -5%;
    z-index: -1;
    img {
      width: 80%;
      height: auto;
    }
  }

  .image-selector {
    margin-top: 0;
    .svg-container .onyx-export {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .measure-container {
    .container .image-container img {
      height: auto;
      width: 100%;
    }
    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h4 {
        width: 100%;
        color: #d8a202;
        width: 60%;
      }
    }
  }
  .svg-fleche {
    text-align: right;
    height: fit-content;
    margin-top: -100px;
    svg {
      height: 200px;
      margin-right: 45%;
      transform: rotate(37deg);
      margin-top: -5%;
    }
  }

  .how-container .description span {
    line-height: 40px;
    font-size: 35px;
  }

  .documentation-container {
    margin-left: 0;
    margin-top: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .download-documentation {
      margin-left: 10%;
    }
    .catalogue {
      width: 50%;
    }
  }

  #svg-accueil-cc {
    width: 40%;
    inset: 10% 60%;
    top: 10%;
    left: 60%;

    .onyx-export {
      transform: translateY(0) !important;

      h3 {
        font-family: Amatic-Bold;
        color: #1f8491;
        font-size: 40px;
        margin-bottom: 20%;
        width: 55%;
      }

      svg {
        display: block;
        margin-bottom: 10%;
      }

      span {
        font-weight: bold;
        line-height: 50px;
        font-size: 50px;
      }
    }
  }

  .explication-container {
    display: flex;
    justify-content: center;
  }

  .crystalcloud-container {
    &.accueil #svg-accueil-cc {
      inset: 20% 60% !important;
      top: 20% !important;
      left: 60% !important;
    }

    #svg-accueil-cc {
      inset: 5% 60% !important;
      top: 5% !important;
      left: 60% !important;
      transform: none !important;
      width: 30%;
      span {
        font-weight: bold;
        line-height: 50px;
        font-size: 50px;
      }
    }
  }

  .contact-container {
    height: fit-content;
  }

  .footer-logiciels {
    margin-top: 0;
  }
}

.mecanisme-container {
  margin: 5% 0;
  .container {
    h3 {
      font-family: Amatic-Bold;
    }

    img {
      width: 100%;
      height: auto;
    }

    &.image {
      position: relative;

      .position {
        height: 100%;
        position: absolute;
        top: 50px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.onyx-container {
  overflow-x: hidden;
  animation: startOpacity 1.5s ease-in-out forwards;

  @keyframes startOpacity {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .compatible-container {
    margin-top: 5%;
  }
  .footer {
    .image-container {
      margin-top: -25%;
      h2 {
        margin-top: 35px;
      }
    }
  }

  #svg-earth-onyx {
    align-items: start;
    h3 {
      text-align: left;
    }
    span {
      width: 90%;
    }
  }

  .indicatorBar .padding-bar {
    padding: 0 40%;
  }

  #svg-earth-onyx {
    width: 30% !important;
  }

  #svg-filtre-onyx {
    inset: 100px 2%;
    top: 100px;
    left: 2%;
    position: absolute;
    width: 35%;
    text-align: left;
  }

  #svg-export-onyx {
    position: absolute;
    inset: 25% 20%;
    top: 25%;
    left: 20%;
    width: 30%;
  }

  .svg-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    h3 {
      font-weight: lighter;
    }
    span {
      clip-path: unset !important;
      font-weight: bold;
      line-height: 50px;
      font-size: 50px;
    }
  }

  &.filtres {
    margin-top: 0;
  }

  .footer-logiciels {
    margin-top: 0;
  }

  .onyx-accueil {
    position: absolute;
  }

  .documentation-container {
    margin-bottom: 35%;
    margin-top: -5%;
    margin-left: 0;
    .button-download {
      justify-content: center;
    }
  }

  .svg-filtre {
    margin-left: 5%;
  }

  .image-onyx {
    position: fixed;
    top: 104px;
    z-index: 10;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 50%;
    top: 30%;
    img {
      height: 7em;
      width: auto;
      margin-bottom: 5%;
    }

    .download-documentation .button-download{
      justify-content: center;
    }

    .android-container {
      .text {
        text-align: center;

        h3 {
          font-family: CandleLight;
          margin-bottom: 0;
          color: black;
          font-size: 80px;
          line-height: 75px;
        }
      }
    }
  }
}

.contact-container {
  height: 100%;
  padding: 10%;
  background-color: #f2f2f2;

  .telephone-number {
    position: relative;
    background-color: white;
    box-shadow: 2px 2px 10px 2px grey;
    padding: 20px;
    border-radius: 10px;
    width: max-content;
    margin-top: 20%;
    left: -100%;
    .text-container {
      h3 {
        font-weight: 800;
        font-size: 32px;
        margin-bottom: 0;
      }
      span {
        float: right;
        font-size: 12px;
        font-style: italic;
        color: grey;
      }
    }
    .triangle-container {
      filter: drop-shadow(-3px -2px 2px rgba(50, 50, 0, 0.5));
      width: fit-content;
      top: -40px;
      left: 10%;
      position: absolute;
      .triangle {
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
        background-color: white;
        height: 40px;
        width: 40px;
      }
    }
  }

  .top-container {
    margin-left: 7%;
    display: flex;
    .mail-box {
      display: flex;
      position: relative;
      width: fit-content;
      margin-left: 5%;
      align-self: flex-end;
      width: 30%;
      .svg-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;

        h3 {
          font-family: Amatic-Bold;
          margin-bottom: 0;
          color: grey;
        }
      }

      .fleche-mail {
        position: absolute;
        top: 90%;
        left: 25%;
      }
    }
    .image-container {
      display: flex;
      justify-content: center;
      width: 30%;
      margin-left: -30px;
      img {
        height: 12em;
      }
    }
  }

  .center-container {
    display: flex;
    margin-top: 10vh;
    margin-left: 7%;
    *:focus {
      outline: 0px !important;
      -webkit-appearance: none;
      box-shadow: none !important;
    }

    .formulaire-container {
      box-shadow: 2px 2px 10px 2px grey;
      background-color: white;
      border-radius: 15px;
      padding: 25px;
      position: relative;
      height: 100%;

      .triangle-container {
        filter: drop-shadow(4px 2px 2px rgba(50, 50, 0, 0.5));
        position: absolute;
        right: -99px;
        top: 20%;
        width: fit-content;
        .triangle {
          background-color: white;
          height: 100px;
          width: 100px;
          clip-path: polygon(0 0, 0% 100%, 100% 0);
        }
      }

      .section {
        padding: 5px;
        border-bottom: 1px solid lightgrey;
        input {
          border: none;
          padding: 0;
        }

        textarea {
          border: none;
          padding: 0;
          height: 100%;
          resize: none;
        }
      }

      .svg-send {
        position: absolute;
        width: fit-content;
        position: absolute;
        bottom: 25px;
        right: 25px;
        cursor: pointer;
      }
    }

    .image-contact {
      margin: 0 5%;
      width: 30%;
      min-width: 300px;
      margin-left: 2%;
      img {
        width: 100%;
        height: auto;
      }
    }

    .device-box {
      width: 20%;
      padding-top: 10%;
      margin-left: 5%;
      position: relative;

      .svg-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin-left: 15%;

        h3 {
          font-family: Amatic-Bold;
          margin-bottom: 0;
          color: grey;
        }
      }
      .fleche-phone {
        position: absolute;
        bottom: -3.5em;
        left: 0;
      }
    }
  }
}

.compatible-container {
  margin: 10% 0;
  padding: 0 10%;

  .materiels-container {
    .vitrine .card {
      width: 320px;
      .image-container {
        height: 500px;
        position: relative;
        img {
          object-fit: contain;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .logiciels-container {
    .vitrine .card {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 0;
      .image-container {
        width: 50%;
      }

      .button-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
          margin-bottom: 15%;
        }

        button {
          border-color: black;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            margin: 0;
            margin-left: 15px;
          }
        }
      }
    }
  }

  .text-container {
    width: 50%;
    display: flex;
    justify-content: center;

    h2 {
      font-family: Times;
      margin-bottom: 0;
      font-weight: bold;
      color: black;
      font-size: 80px;
    }
  }

  .menu-compatible {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    position: relative;
    .sections {
      width: 50%;
      display: flex;
      align-items: center;
      margin-top: 5vh;
      flex-wrap: wrap;
      .section-svg {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        border-right: 1px solid grey;
        padding: 20px 0;

        .svg-compatible {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .vitrine {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card {
      padding: 25px;
      border: none;
      .button-container {
        display: flex;
        justify-content: center;
        button {
          border: 1px solid #b77830;
          color: black;
          background-color: white;
          border-radius: 30px;
          margin-top: 20px;
          width: 70%;

          svg {
            margin-left: 15px;
            transform: rotate(15deg);
          }
        }
      }

      .image-container {
        display: flex;
        justify-content: center;
        img {
          width: 70%;
          padding: 5px;
        }
      }

      &:nth-child(4) {
        margin-right: 0;
      }
    }
  }
}

.mbox-container {
  .filter {
    img {
      width: 80%;
      height: auto;
    }
  }

  .documentation-container {
    margin-left: 0;
    margin-top: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .catalogue {
      width: 50%;
    }
  }

  .footer-logiciels {
    margin-top: 0;
  }

  .documentation-container {
    margin-left: 0;
    margin-right: 10%;

    .button-download {
      justify-content: center;
    }
  }

  .measure-container {
    height: 100vh;
    display: flex;
    .measure {
      width: 45%;
      margin-left: 5%;
      h4 {
        align-self: flex-start;
        color: #1f8491;
        text-align: left;
        width: 100%;
        line-height: 60px;
      }
    }
    .image-container {
      justify-content: left;
      img {
        margin-top: 10%;
      }
    }

    .container {
      max-width: 85%;
      margin-left: 0;
      .image-container {
        justify-content: center;
        height: fit-content;
        img {
          margin-top: 0;
          height: auto;
          width: 100%;
        }
      }
    }
  }

  .partenaire-container {
    background-color: #1f8491;
    padding: 5% 10%;

    .logo-container {
      margin-top: 5%;
      .logo-svg-container {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          margin-right: 5%;
          margin-top: 5%;
        }
      }
    }

    h2,
    h1 {
      font-family: CandleLight;
      margin-bottom: 0;
      color: black;
    }

    h2 {
      font-size: 60px;
    }

    h1 {
      font-size: 80px;
    }
  }

  .explication-container {
    display: flex;
    justify-content: center;
    .image-container {
      img {
        margin-bottom: -6%;
        width: 100%;
        height: auto;
      }
    }
  }

  .schema-container {
    display: flex;
    padding: 0 10%;
    margin-bottom: 15%;
    .fleche {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      z-index: 10;
      margin-left: 25%;
      margin-top: -5%;
    }
    .svg-schema-container {
      width: 70%;
      margin-left: 30%;
      position: relative;
      .text-container {
        width: fit-content;
        transform: translate(-150%, 150%);
        h3 {
          font-size: 50px;
          font-family: Amatic-Bold;
          color: #1f8491;
        }
      }
    }

    .kangoo-container {
      position: absolute;
      width: 40%;
      transform: translate(5%, 70%);
    }
  }

  .how-container {
    padding: 5% 10%;
    display: flex;
    .description {
      width: 50%;
      display: flex;
      align-items: center;

      span {
        font-weight: bold;
        line-height: 40px;
        font-size: 35px;
      }
    }
    .text-container {
      width: 50%;
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      h2 {
        font-size: 60px;
        line-height: 20px;
      }
      h1 {
        font-size: 80px;
      }

      h2,
      h1 {
        font-family: TrashHand;
        margin-bottom: 0;
        color: black;
      }
    }
  }

  #svg-accueil-cc {
    width: 40%;
    inset: 10% 60%;
    top: 10%;
    left: 60%;

    .onyx-export {
      transform: translateY(0) !important;

      h3 {
        font-family: Amatic-Bold;
        color: #1f8491;
        font-size: 40px;
        margin-bottom: 20%;
        width: 55%;
      }

      svg {
        display: block;
        margin-bottom: 10%;
      }

      span {
        font-weight: bold;
        line-height: 50px;
        font-size: 50px;
      }
    }
  }
}

.how-container {
  padding: 5% 10%;
  display: flex;
  .description {
    width: 50%;
    display: flex;
    align-items: center;

    span {
      font-weight: bold;
      line-height: 50px;
      font-size: 50px;
    }
  }
  .text-container {
    width: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    h2 {
      font-size: 60px;
      line-height: 20px;
    }
    h1 {
      font-size: 80px;
    }

    h2,
    h1 {
      font-family: TrashHand;
      margin-bottom: 0;
      color: black;
    }
  }
}

.materiels-container {
  padding: 0 10%;
  margin-top: 120px;
  overflow-x: hidden;
  max-width: 100%;
  .device-section {
    display: flex;
    max-height: 100%;
    overflow: hidden;
    position: relative;
    .section {
      height: 60vh;
      &.un {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
          background-color: white;
          color: #000;
          border: 1px solid #cf7830;
          border-radius: 25px;

          svg {
            margin-left: 10px;
            transform: rotate(15deg);
            margin-right: 5px;
          }
        }
      }

      &.deux {
        display: flex;
        justify-content: center;
      }

      h2 {
        text-align: center;
      }
    }

    .systeme-container {
      position: relative;
      position: absolute;
      bottom: 10px;
      right: 0;
      width: fit-content;
    }

    .svg-box {
      height: 50%;
    }

    h2 {
      font-family: TrashHand;
      margin-bottom: 0;
      color: black;
      font-size: 60px;
    }

    .section {
      width: 50%;
    }
  }
}

.comparateur-container {
  display: flex;
  padding: 5% 10%;
  background-color: #f2f2f2;
  h2 {
    font-size: 100px;
  }
  h3 {
    font-size: 80px;
    text-align: right;
    padding-right: 20px;
  }

  h2,
  h3 {
    font-family: CandleLight;
    margin-bottom: 0;
    color: black;
  }

  .section {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text-container {
      width: fit-content;
    }

    &.un {
    }

    .comparateur-btn {
      width: fit-content;
      display: flex;
      flex-direction: column;

      svg {
        margin-bottom: 25%;
      }

      button {
        border-color: #1f8491;
        background-color: white;
        color: black;
        border-radius: 25px;
        width: 12vw;
      }
    }
  }
}

.container-mat {
  .legend-bar {
    z-index: 1000;
    position: fixed;
    display: flex;
    top: 52px;
    background-color: #f2f2f2;
    padding: 10px 20%;
    height: 68px;
    width: 100vw;
    .section {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:nth-child(2) {
        border-right: 1px solid grey;
        border-left: 1px solid grey;
      }

      h5 {
        margin-left: 20px;
        margin-bottom: 0;
        color: #b77830;
      }
    }
  }
}

.container-mat .footer-logiciels {
  margin-top: 0;
}

.applications-container {
  display: flex;
  margin-top: 5%;
  .section {
    width: 50%;
    display: flex;

    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 50%;

      button {
        margin-top: 10%;
        background-color: white;
        color: #000;
        border: 1px solid #cf7830;
        border-radius: 25px;

        svg {
          margin-left: 10px;
          transform: rotate(15deg);
          margin-right: 5px;
        }
      }
    }

    .image-container {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 25em;
      }
    }
  }
}

.comparateur-component-container {
  .calculator-container {
    margin-top: 0;
  }
  .footer-logiciels {
    margin-top: 0;
  }
  .top-container {
    margin-top: 52px;
    display: flex;
    .title {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        font-family: CandleLight;
        margin-bottom: 0;
        color: black;
        font-size: 80px;
      }
    }
    .image-container {
      width: 50%;
      display: flex;
      justify-content: center;
      img {
        width: 30%;
      }
    }
  }
}

.calculator-container {
  padding: 5% 20%;
  margin-top: 5%;
  background-color: #f2f2f2;
  position: relative;

  .header {
    display: flex;
    .section {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &.device {
        margin-left: 3%;
      }

      .filter-card {
        position: absolute;
        height: 100%;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;

        svg {
          fill: transparent;
        }

        &:hover {
          background-color: #000000c9;
          transition-duration: 0.3s;

          svg {
            fill: white;
          }
        }
      }

      .ajouter {
        background-color: white;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: grey;
        }
      }

      .device-card {
        .section-card {
          display: flex;
          justify-content: center;
          align-items: center;
          h2 {
            font-family: TrashHand;
            margin-bottom: 0;
            color: black;
            font-size: 50px;
            width: 15ch;
            text-align: center;
          }

          img {
            width: 25%;
          }
        }
      }
    }
  }

  .tab-container {
    display: flex;
    .column {
      height: 100%;
      width: 30%;
      position: relative;
      margin-left: 3%;

      &.device {
        margin-left: 3%;
      }

      .row {
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid grey;
        height: 75px;
        justify-content: center;

        .device-card {
          .section {
            display: flex;
            justify-content: center;

            img {
              height: 150px;
            }
          }
        }

        h4 {
          margin-bottom: 0;
          text-align: center;
        }

        &.add {
          display: flex;
          border-bottom: none;
          height: fit-content;
        }

        .ajouter {
          background-color: white;
          border-radius: 50%;
          height: 80px;
          width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: grey;
          }
        }

        .box {
          width: 50%;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        h5 {
          margin-left: 25%;
          margin-bottom: 0;
          font-family: Amatic-Bold;
          font-size: 25px;
        }
      }
    }
  }
}

.device-selector {
  background-color: black;
}

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal.show .modal-dialog {
  max-width: fit-content;
  padding: 0 25%;
  margin: 5% auto;
}
.modal-body {
  height: 70vh;
  max-height: 100%;
  overflow: scroll;
  .selector-container {
    display: flex;

    .carousel-container {
      display: flex;
    }
    .column {
      width: 50%;
      position: relative;
      .box {
        padding: 20px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgrey;
        cursor: pointer;
        height: 60vh;

        img {
          width: auto;
          height: 80%;
        }

        h5 {
          font-family: trashHand;
          font-size: 40px;
          text-align: center;
        }
      }
    }
  }
}

.contact-component .contact-container {
  padding: 5% 10%;
}

.fiche-technique-container {
  margin-top: 52px;
  height: calc(100% - 52px);
  position: relative;

  .calculator-container {
    margin-top: 0;
  }

  .contact-container .top-container {
    height: auto;
    padding: 0;
  }

  .top-container {
    display: flex;
    height: 50vh;
    padding: 0 15%;

    .title {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
        height: auto;
      }
    }

    .image-container {
      width: 50%;
      display: flex;
      justify-content: center;
      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .dimensions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: moz-fit-content;

    img {
      width: 40%;
      height: auto;
    }
  }

  .header-fiche {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      width: fit-content;
      margin-bottom: 2%;
      h1 {
        font-family: TrashHand;
        margin-bottom: 0;
        color: black;
        font-size: 100px;
      }
    }

    .systeme-container {
      width: fit-content;
    }

    button {
      background-color: white;
      color: #000;
      border: 1px solid #cf7830;
      border-radius: 25px;
      margin-top: 3%;
    }

    .regle {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .footer-logiciels {
    margin-top: 0;
  }

  .caracteristique-container {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      text-align: center;
      margin-bottom: 10vh;
      h2 {
        color: #b7803c;
      }
    }
    .section-container {
      padding: 0 15%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid grey;
        height: 100%;
        position: relative;

        h5 {
          height: 20%;
        }

        h4 {
          height: 20%;
        }

        svg {
          width: 100%;
        }

        &:nth-child(6) {
          border-right: none;
        }

        svg {
          margin: 4vh 0;
        }
        h4,
        h5 {
          margin-bottom: 0;
          text-align: center;
        }

        h5 {
          font-family: Amatic-Bold;
          color: grey;
          font-size: 30px;
        }
      }
    }
  }
}

.poid-container {
  padding: 0 15%;
  display: flex;

  .image-container {
    position: relative;
    width: 60%;
    display: flex;
  }

  .text-container {
    width: 40%;
    height: fit-content;
    align-self: center;

    h3 {
      color: #cf7830;
    }
  }
}

.support-container {
  position: relative;
}

.menu-header {
  position: relative;
  justify-content: flex-start;
  .mini-menu {
    width: 100%;
    position: absolute;
    height: 52px;
    display: flex;
    align-items: center;
    .hamburger {
      cursor: pointer;
      position: absolute;
      width: 48px;
      height: 48px !important;
      transition: all 0.25s;
      right: 0;
    }

    .hamburger__top-bun,
    .hamburger__bottom-bun {
      content: "";
      display: block;
      position: absolute;
      left: 15px;
      width: 18px;
      height: 1px;
      background: #fff;
      transform: rotate(0);
      transition: all 0.25s;
    }

    .hamburger:hover [class*="-bun"] {
      background: #999;
    }

    .hamburger__top-bun {
      top: 23px;
      transform: translateY(-3px);
    }

    .hamburger__bottom-bun {
      bottom: 23px;
      transform: translateY(3px);
    }

    .open {
      transform: rotate(90deg);
    }

    .open .hamburger__top-bun {
      transform: rotate(45deg) translateY(0px);
    }

    .open .hamburger__bottom-bun {
      transform: rotate(-45deg) translateY(0px);
    }
    .center-menu {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .li-0 {
        animation: translateX 200ms ease-in-out forwards;
        transform-origin: top center;
      }

      .li-1 {
        animation: translateX 400ms ease-in-out forwards;
        transform-origin: top center;
      }

      .li-2 {
        animation: translateX 600ms ease-in-out forwards;
        transform-origin: top center;
      }

      .li-3 {
        animation: translateX 800ms ease-in-out forwards;
        transform-origin: top center;
      }

      .li-4 {
        animation: translateX 1200ms ease-in-out forwards;
        transform-origin: top center;
      }

      .li-5 {
        animation: translateX 1400ms ease-in-out forwards;
        transform-origin: top center;
      }

      @keyframes translateX {
        0% {
          opacity: 0;
          transform: translateX(-100px);
        }

        80% {
          transform: translateX(5px);
        }

        100% {
          opacity: 1;
          transform: translateX(0px);
        }
      }

      li {
        padding: 20px;
        border-bottom: 1px solid lightgrey;
        &:hover {
          color: #cf7830;
          border-color: #cf7830;
        }
      }
    }
  }
}

.modal.show .modal-dialog {
  padding: 0 5px;
  position: fixed;
}

.annotation {
  position: fixed;
  inset: 70%;
  top: 70%;
  left: 70%;
  opacity: 0;

  &.saphir {
    inset: 10% 70%;
    top: 10%;
    left: 70%;
  }

  &.start {
    animation: increaseOpacity 600ms ease-in-out forwards;
    opacity: 1;
  }

  &.end {
    animation: decreaseOpacity 600ms ease-in-out forwards;
    opacity: 0;
  }

  h5 {
    background-color: #000000c9;
    width: fit-content;
    font-family: CandleLight;
    margin-bottom: 0;
    color: white;
    font-size: 60px;
    line-height: 75px;
    border-radius: 25px;
    padding: 20px;
    text-align: center;
  }
}

@keyframes increaseOpacity {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes decreaseOpacity {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.crystalcloud-container {
  #svg-filtre-crystalcloud {
    padding: 0 15%;
    text-align: center;
    height: 350px;
    position: relative;
  }

  .footer-logiciels {
    position: relative;
  }
  .scroll-bound {
    height: 4000vh;

    .content {
      height: 100vh;
      width: 100%;
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    video {
      width: 100%;
    }
  }
}

.look-back {
  position: fixed;
  bottom: 10px;

  .svg {
    width: fit-content;
    background-color: white;
    border-radius: 50%;
    border: 1px solid lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: lightgrey;

      svg {
        fill: white;
      }
    }
  }
}

.menu-header .header .menu-header .navbar-nav .nav-item a {
  &:hover {
    color: #31c6b3 !important;
  }
}

.support-container {
  .image-container {
    height: 125vh;
    position: relative;
    background: url("./_resources/image/support_dioptase.jpg");
    background-size: cover;
    img {
      height: 100%;
      width: auto;
    }

    h1 {
      margin-bottom: 0;
      position: absolute;
      color: #cf7830;
      inset: 15% 55%;
      top: 15%;
      left: 55%;
      font-weight: bold;
      line-height: 50px;
      font-size: 100px;
    }

    h5 {
      position: absolute;
      margin-bottom: 0;
      text-align: center;
      color: black;
      font-weight: bold;
      line-height: 40px;
      font-size: 40px;
    }

    .hotline {
      inset: 30% 35%;
      top: 30%;
      left: 35%;
      width: fit-content;
      display: flex;
      flex-direction: column;
      svg {
        margin-left: -70%;
        margin-bottom: 10%;
      }
    }

    .remote-maintenance {
      inset: 60% 55%;
      top: 60%;
      left: 55%;
      width: fit-content;
      position: absolute;
      svg {
        margin-left: 70%;
        margin-bottom: 10%;
      }
    }
  }
  .span-container {
    padding: 5% 10%;
    text-align: center;
    span {
      color: lightgrey;
      font-size: 25px;
      color: #a5a5a5;
    }
  }
  .section-container {
    display: flex;
    justify-content: space-between;
    padding: 0 25%;
    margin-bottom: 5%;
    flex-wrap: wrap;
    .section {
      width: 200px;
      padding: 15px;
      .svg-box {
        margin-bottom: 20px;
      }

      .title h5 {
        text-align: center;
        font-family: TrashHand;
        margin-bottom: 0;
        color: black;
        font-size: 25px;
      }
    }
  }
}

.lang-selector {
  position: absolute;
  top: 2px;
  right: 30px;
  width: fit-content;
  z-index: 100000;
  cursor: pointer;
  .svg-box {
    svg {
      height: 36px;
    }
  }
  .section {
    text-align: center;
  }
}

.error-container {
  //d8a202
  height: calc(100vh - 52px);
  display: flex;
  .wrapper {
    width: 40%;
    height: calc(100vh - 52px);
    display: flex;
    justify-content: center;
    align-items: center;
    .center {
      height: fit-content;
      height: moz-fit-content;
      width: fit-content;
      width: moz-fit-content;

      h1 {
        font-family: SilentLandfield;
        color: #d8a202;
        font-size: 200px;
        line-height: 100px;
        text-align: center;
      }

      h3 {
        font-weight: bold;
        font-size: 35px;
        text-align: center;
      }

      .span {
        margin: 5vh 0;
      }

      .btn-container {
        display: flex;
        justify-content: center;
        button {
          background-color: #d8a202;
          color: white;
          border: none;
          width: 170px;
        }
      }
    }
  }
  .image-background {
    width: 60%;
    &.construction {
      background: url("./_resources/image/oups.png");
    }

    &.not-found {
      background: url("./_resources/image/404.png");
    }
  }
}

.__react_component_tooltip {
  max-width: auto !important;
  width: auto;
}

.timeline-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .fill-bar {
    width: 15px;
    height: 100%;
    border-radius: 10px;
    position: relative;
    .in-progress {
      border-radius: 0 0 10px 10px;
    }
  }

  .bar {
    width: 15px;
    height: 8%;
    border-radius: 10px;
    position: relative;

    .in-progress {
      border-radius: 10px;
    }
  }

  .round {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    margin: 10px 0;
    svg {
      fill: white;
    }
  }
}

.dioptase-container {
  .canvas-container {
    canvas {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.center-animation {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text-container {
    text-align: center;
    h5 {
      font-family: Amatic-Bold;
      font-size: 40px;
      color: black;
    }
  }

  .scroll-animation {
    width: 50px;
    height: 80px;
    border-radius: 25px;
    border: 3px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .svg-anim {
      animation: scrollDown 2000ms infinite;
    }

    @keyframes scrollDown {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(60px);
      }
      51% {
        opacity: 0;
      }
      52% {
        transform: translateY(-60px);
      }
      53% {
        opacity: 1;
      }

      100% {
        transform: translateY(0);
      }
    }
  }
}

.popup {
  .valid {
    background-color: white;
    color: black;
    border-color: #198754;
    &:hover {
      background-color: #198754;
      color: white;
    }
  }
  .cancel {
    background-color: white;
    color: black;
    border-color: grey;
    &:hover {
      background-color: grey;
      color: white;
    }
  }
}

.home-title{
  z-index: 10;
  text-align: center;
  transform: translateY(50%);
  line-height: 8px;
  word-spacing: 2px;
  display: none;
  h1{
    font-size: 4rem;
    font-weight: 300;
    margin:0;
    text-transform: uppercase;
  }
}
.social-network{
  background-color: #333;
  display: flex;
  justify-content: center;
  .linkedin{
    width: fit-content;
    width: -moz-fit-content;
    background-color: #333;
  }
}