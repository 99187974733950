@media (max-width: 1600px){
    body{
        .maconso-container{
            .first-anim .b40.center{
                //transform: translateX(20%) translateY(-15%);
            }
            .box{

                svg {
                    height: 45px;
                    width: auto;
                }
        
                .span-container {
                    width: 23ch;
                    text-align: center;
                    font-size: 20px;
        
                    span {
                        font-weight: bold;
                    }
                }
        
                h5 {
                    text-align: center;
                    font-weight: 300;
                    color: grey;
                    font-size: 20px;
                    margin: 15px 0 30px 0;
                }
            }
        }
    }
}